import React from 'react';
import { css } from "../../../styled-system/css";
import ResponsiveImage from "./ResponsiveImage";
import Pargh from './Pargh';

interface Props {
  titles: string[];
  images: string[];
  HtmlText: string[];
}

const ImageTextSection: React.FC<Props> = ({ titles, images, HtmlText }) => {
  return (
    <>
      {/* Mapping over images, titles, and HtmlText */}
      {images.map((image, index) => {
        // Check if there's associated text, otherwise skip rendering
        if (index === 0 || !HtmlText[index]) {
          return null; // Skip rendering this block if no associated text
        }

        return (
          <div key={index}>
            <h2
              className={css({
                fontSize: '30px',
                width: '100%',
                textAlign: 'center',
                margin: '50px auto 0',
              })}
            >
              {titles[index] || `Title ${index + 1}`}
            </h2>

            {/* Full-width styling (for every third item) */}
            {index % 3 === 2 ? (
              <div
                className={css({
                  display: 'flex',
                  flexDir: 'column',
                  width: '100%',
                  maxWidth: '1300px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '50px auto 0',
                })}
              >
                <ResponsiveImage
                  className={css({
                    width: '100%',
                    aspectRatio: 'auto',
                    objectFit: 'cover',
                  })}
                  imageName={image}
                />
                <Pargh
                  className={`phototext ${css({
                    width: '90%',
                    fontSize: '16px',
                    textAlign: 'center',
                    margin: '20px auto',
                  })}`}
                  file={HtmlText[index]}
                />
              </div>
            ) : index % 3 === 0 ? (
              // Image on the left, text on the right (for even index % 3 === 0)
              <div
                className={css({
                  display: 'flex',
                  flexDir: 'row',
                  width: '100%',
                  maxWidth: '1300px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: '50px auto 0',
                  gap: '5%',
                  '@media (max-width: 768px)': {
                    flexDir: 'column',
                  },
                })}
              >
                <ResponsiveImage
                  className={css({
                    width: '50%',
                    aspectRatio: 'auto',
                    objectFit: 'cover',
                    borderRightRadius: '4vh',
                    '@media (max-width: 768px)': {
                      width: '100%',
                      margin: 'auto',
                      borderRadius: '0',
                    },
                  })}
                  imageName={image}
                />
                <Pargh
                  className={`phototext ${css({
                    width: '100%',
                    fontSize: '10px',
                    '@media (max-width: 768px)': {
                      width: '90%',
                      margin: 'auto',
                      textAlign: 'center',
                    },
                  })}`}
                  file={HtmlText[index]}
                />
              </div>
            ) : (
              // Image on the right, text on the left (for odd index % 3 === 1)
              <div
                className={css({
                  display: 'flex',
                  flexDir: 'row-reverse',
                  width: '50%',
                  maxWidth: '1300px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  margin: '50px auto 0',
                  gap: '5%',
                  '@media (max-width: 768px)': {
                    width: '100%',
                    marginTop: '10px',
                    flexDir: 'column',
                  },
                })}
              >
                <ResponsiveImage
                  className={css({
                    width: '100%',
                    aspectRatio: 'auto',
                    objectFit: 'cover',
                    borderLeftRadius: '4vh',
                    '@media (max-width: 768px)': {
                      width: '100%',
                      margin: '10px auto 0',
                      borderRadius: 0
                    },
                  })}
                  imageName={image}
                />
                <Pargh
                  className={`phototext ${css({
                    width: '100%',
                    '@media (max-width: 768px)': {
                      width: '90%',
                      margin: 'auto',
                      textAlign: 'center',
                    },
                  })}`}
                  file={HtmlText[index]}
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ImageTextSection;
