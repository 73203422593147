import React from 'react'
import ButtonOrd from './ButtonOrd' 
import { css } from '../../../styled-system/css'

interface HeroSectionProps {
  titre: string
  id: string
}

const HeroSection: React.FC<HeroSectionProps> = ({ titre, id }) => {
  return (
    <div
      className={css({
        position: 'absolute',
        width: '50%',
        padding: '1rem 0',
        textAlign: 'center',
        fontSize: '22px',
        fontWeight: 'bold',
        zIndex: 99,
        bottom: 0,
        left: '50%',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        border: '1px solid white',
        transform: 'translateX(-50%)',
        '@media (max-width: 768px)': {
          left: '0%',
          width: '100%',
          transform: 'none'
        },
      })}
    >
      <h1
        className={css({
          width: '100%',
          textAlign: 'center',
          fontSize: '2rem',
          fontWeight: 'bold',
          zIndex: 1,
          '@media (max-width: 768px)': {
            fontSize: '30px',
          },
        })}
      >
        {titre}
      </h1>
      <h2>Chéreng - Lille - Nord</h2>
      <ButtonOrd
        className={css({
          boxShadow: 'none',
          color: '#444',
          border: '1px solid #fff',
        })}
        pkgId={id}
      />
    </div>
  )
}

export default HeroSection
